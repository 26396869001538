body {
  background: linear-gradient(#f5f8fa, #eceff1);
}

#canvas {
  padding: 64px 0;
  width: 100%;
  height: 100vh;
  overflow: scroll;

}

.MuiOutlinedInput-root {
  background-color: #fff;
}